import {Privacy} from './Components/Privacy/Privacy'
import {Impressum} from './Components/Impressum/Impressum'
import './css/Legal.css'
import {useEffect} from "react";

export function PrivacyPolicy(){
    useEffect(()=>{
        window.scrollTo(0,130)
    },[])
    return (
    <div className="legal-container">
        <Privacy/>
        <Impressum/>
    </div>
    )
}