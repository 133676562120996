import "./About.css"
import images from "./assets/assets"
import Pillars from "../Components/Pillars";
import {Citation} from "./Components/Citation";
import Introduction from "../Components/Introduction";

export function About()
{

    return (
        <div className="about-page">
            <Introduction
                image = {images.ImageTitle}
                headline = "Über mich"
                subheadline = "Susanne Beckers"
                contentText = "Bildungsmanagerin (MA)<br/>Referentin Erwachsenenbildung<br/>QueB Coachin<br/>Multiplikatorin des bay. Sozialministeriums/IFP"
            />
            <Citation
                text="Schon früh habe ich mich für psychologische und pädagogische Kontexte interessiert, war dann zuerst in der Wirtschaft engagiert und für einige Jahre als Führungskraft international tätig. Der Wunsch, in der Pädagogik tätig zu werden, ließ mich nicht los und deshalb entschied ich mich vor mehr als 20 Jahren, einen neuen beruflichen Weg zu gehen. <br/>Als freiberufliche Fortbildnerin für Erwachsene bin ich inzwischen regelmäßig für verschiedene Bildungsträger tätig. <br/>Meine Schwerpunkte sind pädagogische Themen, Bewegung in Kitas/bewegtes Lernen sowie Organisationsentwicklung in Kitas. "
                image = {images.ImageCitation}
                headline=""
                citation={true}

            />
            <Pillars
                headline="Perspektivwechsel"
                introText="Als Geschäftsführung zweier integrativer Einrichtungen in Sonthofen bin ich mir des Fachkräftemangels sehr bewusst und möchte aktiv dazu beitragen, dem entgegen zu steuern. Ich bin nah an der Praxis, da ich jeden Tag die Herausforderungen im Praxisfeld erlebe und die Möglichkeit eines Perspektivwechsels im Personalmanagement wichtig finde."

                leftPillarIcon={images.IconCourses}
                leftPillarHeadline="Kurse"
                leftPillarText="Ich interessiere mich für andere (Bildungs-) Kulturen und koordiniere regelmäßig Kurse und Hospitationen für Erzieher*innen und Lehrkräfte ins europäische Ausland."

                centerPillarIcon={images.IconBinoculars}
                centerPillarHeadline="Horizont erweitern"
                centerPillarText="Den Horizont zu erweitern und am Unterschied zu lernen – das sind meine Fokusthemen! Dabei spielt das Denken „out of the box“ eine große Rolle für mich, denn nur so können wir Neuem begegnen."

                rightPillarIcon={images.IconChildren}
                rightPillarHeadline="Kinder"
                rightPillarText="Kinder sind unsere Zukunft und diejenigen, die sie bilden und erziehen haben einen wichtigen Auftrag. Ich begleite Sie bei dieser wichtigen Aufgabe und gebe Ihnen Anregungen für Ihre eigene Entwicklung."
            />
        </div>
    )
}