import Introduction from "../Components/Introduction";
import images from "./assets/assets";
import media from '../../media/assets'
import {TextBoxWithImageExtended} from "../Components/TextBoxWithImageExtended";
export function Ergaenzungskraft()
{
    return (
        <div className="courses-page">
            <Introduction
                image = {images.Image_Peacock}
                headline = "Ergänzungskraft"
                subheadline = "Auf dem Weg zur Ergänzungskraft"
                contentText = "Sie arbeiten gerne mit Kindern und wollen ihre Leidenschaft zum Beruf machen? <br/>Sie sind Tagesmutter/-vater oder Assistenzkraft in Kitas und wollen sich weiter qualifizieren?"

                button={true}
                buttonText="➔ Zur Anmeldung"
                buttonDestination={media.Signup_Form_Ergaenzungskraft}
                buttonTextColor="#2B2E34"
            />

            <TextBoxWithImageExtended
                headline="Wie geht das?"
                text="Sie lernen die theoretischen Grundlagen Ihrer neuen Tätigkeit in ihrer Qualifizierung kennen und arbeiten gleichzeitig in einer Kita/Minikita. In nur wenigen Monaten entwickeln Sie sich und Ihr Wissen so weiter, dass sie in Bayern in jeder Kita (Krippe/Kindergarten/Hort) qualifiziert arbeiten dürfen und Kinder in ihrer Entwicklung begleiten."
                image={images.Image_ChickPlayingInMud}
                citation={true}

                button1={true}
                button1Text = "Kombikurs April"
                button1Destination = {media.KombiKursModul3und4AbApril}
                button1TextColor="#2B2E34"

                button2={true}
                button2Text="Kombikurs Oktober"
                button2Destination = {media.KombiKursModul3und4AbOktober}
                button2TextColor="#2B2E34"

                button4={true}
                button4Text="➔ Mehr Informationen"
                button4Destination = "https://www.kita-fachkraefte.bayern/"
                button4TextColor="#2B2E34"
            />
        </div>
    )
}