import Image_TableColors from './Image_TableColors.svg'
import Image_ForestStanding from './Image_ForestStanding.png'
import Image_TableColors2 from './Image_TableColors2.png'
import Image_ChestCaps from './Image_ChestCaps.png'
import Image_ChildEnglish from './Image_ChildEnglish.png'

export default {
    Image_TableColors,
    Image_ForestStanding,
    Image_ChestCaps,
    Image_TableColors2,
    Image_ChildEnglish
}