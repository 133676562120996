import Erasmus_Project from './files/ERASMUS_PROJEKT_2022-A4_4c_web_Juni_22.pdf'
import Assistenzkraft_Ingolstadt from './files/Assistenzkraft_Ingolstadt.pdf'
import Modul3 from './files/Block B_Modul_3_Kurs_2-2023.pdf'
import Modul4 from './files/Block B_Modul_4_Kurs_2-2023.pdf'
import Signup_Form_Ergaenzungskraft from './files/Anmeldeformular_Ergaenzungskraft.pdf'
import Signup_Form_Assistenzkraft from './files/Anmeldeformular_Assistenzkraft.pdf'
import KombiKursModul3und4AbOktober from './files/KombikursModul3und4AbOktober.pdf'
import Assistenzkraft_A_1_Nov from './files/Assistenzkraft_Block_A_Modul_1_Kempten_Nov_2024.pdf'
import Assistenzkraft_A_2_Mar from './files/Assistenzkraft_Block_A_Modul_2_Kempten_Mar_2025.pdf'
import Assistenzkraft_A_2_Sep from './files/Assistenzkraft_Block_A_Modul_2_Kempten_Sept_2024.pdf'

export default {
    KombiKursModul3und4AbOktober,
    Signup_Form_Ergaenzungskraft,
    Signup_Form_Assistenzkraft,
    Erasmus_Project,
    Assistenzkraft_Ingolstadt,
    Modul3,
    Modul4,
    Assistenzkraft_A_1_Nov,
    Assistenzkraft_A_2_Mar,
    Assistenzkraft_A_2_Sep
}