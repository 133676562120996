import './css/FullSiteText.css'

export default function FullSizeText(props){
    return (
        <div className="fullsizetext-container" style={{
            backgroundImage: "url(" + props.background + ")"

        }}>
            <div className="fullsizetext-container__text">
                <p className="fullsizetext-heading">
                    {props.headline}
                </p>
                <p className="fullsizetext-content">
                    {props.text.replaceAll("<br/>",'\n')}
                </p>
            </div>
        </div>
    )
}