import "./css/TextBoxWithImage.css"
import images from '../About/assets/assets'
import {Button} from "./Button";
export function TextBoxWithImage(props){
    return (
        <div
            style={{backgroundColor: props.backgroundColor}}
            className="textboxwithimage-container"
        >
            <div className="box">
                {props.citation && (
                    <img src={images.IconQuote} className="quote-upper" alt="Icon showing an upper quote"/>
                )}
                <div className="text-box">
                    {props.headline && (
                        <p className="page-container__headline">
                            {props.headline}
                        </p>
                    )}

                    <p className="page-container__text">
                        {props.text.replaceAll('<br/>','\n')}
                    </p>
                    <div className="button-container">

                        {props.logo && (
                            <div style={{
                                width: "100%",
                                paddingTop: "20px"
                            }}>
                            <img src={props.logo} alt="Erasmus+ Logo"/>
                            </div>
                        )}

                        {props.button1 && (
                            <Button
                                text={props.button1Text}
                                buttonDestination={props.button1Destination}
                                buttonTextColor={props.button1TextColor}
                                subheading={props.button1Subheading}
                            />
                        )}
                        {props.button2 && (
                            <Button
                                text={props.button2Text}
                                buttonDestination={props.button2Destination}
                                buttonTextColor={props.button2TextColor}
                                subheading={props.button2Subheading}

                            />
                        )}
                        {props.button3 && (
                            <Button
                                text={props.button3Text}
                                buttonDestination={props.button3Destination}
                                buttonTextColor={props.button3TextColor}
                                subheading={props.button3Subheading}
                            />
                        )}
                        {props.button4 && (
                            <Button
                                text={props.button4Text}
                                buttonDestination={props.button4Destination}
                                buttonTextColor={props.button4TextColor}
                                subheading={props.button4Subheading}
                            />
                        )
                        }
                    </div>
                    <div className="button-container">
                        {props.button5 && (
                                <Button
                                    text={props.button5Text}
                                    buttonDestination={props.button5Destination}
                                    buttonTextColor={props.button5TextColor}
                                    subheading={props.button5Subheading}
                                />
                            )}
                    </div>
                </div>
                {props.citation && (
                    <img src={images.IconQuote} className="quote-lower" alt="Icon showing a lower quote"/>
                )}
            </div>
            <img className ="side-image" src={props.image} alt=""/>

        </div>
    )
}