import Pillars from "../Components/Pillars";
import assets from "./assets/assets";

export function Contact()
{
    return (
        <>
            <Pillars
                headline="Kontakt"
                introText=""
                backgroundColor="#ddd0c7"
                heightSubstraction="150px"

                leftPillarIcon={assets.Icon_Telephone}
                leftPillarHeadline="Telefon"
                leftPillarText="+49 (0) 163 / 6951 297 <br/><br/>Ich freue mich über Deinen Anruf."

                centerPillarIcon={assets.Icon_EMail}
                centerPillarHeadline="E-Mail"
                centerPillarText="susanne-beckers@t-online.de<br/><br/>Kontaktiere mich per E-Mail."

                rightPillarIcon={assets.Icon_Zoom}
                rightPillarHeadline="Zoom"
                rightPillarText="Triff mich über Zoom. <br/>Schreib mir eine E-Mail und ich sende Dir gerne unseren virtuellen Gesprächslink zu."
            />
        </>
    )
}