import Introduction from "../Components/Introduction";
import images from "./assets/assets";
import {TextBoxWithImage} from "../Components/TextBoxWithImage";
import {TextBoxWithImageInversed} from "../Components/TextBoxWithImageInversed";
import FullSizeText from "../Components/FullSizeText";

export function Workshops()
{
    return (
        <div>
            <Introduction
                image = {images.Image_TableColors2}
                headline = "Workshops"
                subheadline = "Benjamin Franklin:"
                contentText = "Eine Investition in Wissen bringt noch immer die besten Zinsen."
            />
            <TextBoxWithImage
                headline="Sprachsensibilisierung"
                text = "Elf Jahre lang begleitete ich unsere Einrichtungen als Sprachkitas. Sprache ist der Schlüssel zur Welt und ermöglicht Menschen Teilhabe. Menschen mit einer anderen Herkunftssprache als Deutsch finden sich in unserer Kultur und in unserem Wertesystem nicht immer zurecht. Dabei kann Sprache zur Aus- und Abgrenzung beitragen oder die Integration erleichtern.<br/>Wie gehen wir in der Kita mit dieser Herausforderung um? Ich biete Workshops, zum Beispiel zu den Themen „Sprachvorbild“, „Werte“ oder auch „Ausgrenzung“, an."
                image = {images.Image_ChildEnglish}
                citation={true}
            />

            <TextBoxWithImageInversed
                headline="Organisationsentwicklung"
                subtitle="Organisationsentwicklung wird auch in Kitas ein immer wichtigeres Thema. Unsere Abläufe zu optimieren und die Zusammenarbeit für alle Akteure einfacher zu machen, sind Ziele der Organisationsentwicklung. Folgende Workshops helfen Ihnen dabei, Ihre Kita besser zu organisieren: "
                box_text="➔ Einarbeitung<br/>➔ Zeitmanagement<br/>➔ Jahresgespräche erfolgreich führen<br/>➔ Strategieentwicklung in der Kita"
                image = {images.Image_ChestCaps}
            />

            <FullSizeText
                headline={`"Visualisieren in der Kita"`}
                text="Kinderbücher voller Bilder und kaum Text? Das kennt jeder in der Kita und die Kinder lernen so ganz einfach. Aber ab einem gewissen Alter lösen sich die Lernmethoden von der Bebilderung und es gibt Text über Text. Dabei funktioniert die Vermittlung von Inhalten, die durch Bilder unterstützt wird, praktisch lebenslang und noch dazu viel besser. <br/><br/>Das Prinzip nennt sich Visualisierung und klappt in vielen Bereichen ausgezeichnet. Das Gehirn verarbeitet Bilder schneller als gesprochene oder geschriebene Informationen, so dass Bilder vom Gehirn effizienter kodiert und damit besser „abgelegt“ werden. Eine so starke Sensorik ist eine großartige Basis, wenn man sie richtig einsetzt. Denken Sie an Ihre Teamgespräche und Elternabende: Vollgeschriebene Folien in der Powerpoint-Präsentation sind mühsam, doch bei Charts oder Notizen an einer Tafel kommt das Thema schon viel besser an.<br/><br/>Lernen Sie die Grundlagen der Visualisierung online und nutzen Sie das Gelernte auf Ihrer Flipchart oder einfach, um auf einem Blatt Papier Ihre Themen zu verbildlichen und deutlicher darzustellen. Grundsätzliche Gestaltungselemente für den pädagogischen Alltag, Detailhinweise zum Visualisieren in der Kita und praxiserprobte Tipps zeigen Ihnen, wie Sie Schritt für Schritt auch scheinbar schwierige zeichnerische Herausforderungen meistern können. Jeder kann zeichnen! "
                background={images.Image_TableColors}
            />

            <TextBoxWithImage
                headline = "Emotionale Kompetenzen im Wald"
                text= "Auch Lust auf draußen? Der Erlebnisraum Wald weckt den Entdeckergeist, aktiviert die Sinne, tut gut und ist in hervorragender Weise dazu geeignet, emotionale Kompetenzen der Kinder zu schulen. Ich zeige ihnen, wie man mit den Kindern im Wald Wut und Ärger verarbeitet, aber auch Freude und Mut in sich entdeckt."
                image = {images.Image_ForestStanding}
                citation={true}
            />
            <TextBoxWithImageInversed
                backgroundColor="white"
                box_headline = "Mein Angebot"
                box_text= "Alle Workshops sind als halbtägige (drei Stunden) Veranstaltungen buchbar und können als Teamfortbildung gerne individuell zusammengestellt werden."
                image = {images.Image_TableColors}
            />
        </div>
    )
}