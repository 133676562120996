import {TextBoxWithImage} from "../../Components/TextBoxWithImage";

export function Citation(props){
    return (
        <TextBoxWithImage
            headline={props.headline}
            citation={props.citation}
            text={props.text}
            image={props.image}

            button={props.button}

            buttonText ={props.buttonText}
            buttonDestination = {props.buttonDestination}

            buttonColor= {props.buttonColor}
            buttonBorder= {props.buttonBorder}
            buttonTextColor={props.buttonTextColor}
        />
    )
}