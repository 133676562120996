import "./css/TextBoxWithImageInversed.css"
import images from "../About/assets/assets";
export function TextBoxWithImageInversed(props){

    return (
        <div className="textboxwithimage-page">
            <div className="textbox-heading">
                <p id="headline">{props.headline}</p>
                <p id="subtitle">{props.subtitle}</p>
            </div>
            <div className="textboxwithimage-container__inversed">
                <img className="side-image__inversed" src={props.image} alt=""/>
                <div className="box__inversed">
                    {props.citation && (
                        <img src={images.IconQuote} className="quote-upper__inversed" alt="Quote showing an upper quote"/>
                    )}
                    <div className="text-box__inversed">

                        {props.box_headline && (
                            <p className="page-container__headline__inversed">
                                {props.box_headline}
                            </p>
                        )}

                        <p className="page-container__text__inversed">
                            {props.box_text.replaceAll("<br/>",'\n')}
                        </p>
                    </div>
                    {props.citation && (
                        <img src={images.IconQuote} className="quote-lower__inversed" alt="Quote showing an upper quote"/>
                    )}
                </div>
            </div>
        </div>
    )
}