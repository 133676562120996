import Introduction from "../Components/Introduction";
import images from "./assets/assets";
import Pillars from "../Components/Pillars";
import {TextBoxWithImageInversed} from "../Components/TextBoxWithImageInversed";
import {Citation} from "../About/Components/Citation";

import React from 'react'
import Video from "./assets/video/bildung-und-bewegung-was-hinter-dem-konzept-bewegte-kita-steckt.mp4";

export function QuebCoaching()
{
    return (
        <div className="courses-page">
            <Introduction
                image = {images.Image_BewegtesLernen}
                headline = "QueB Coaching"
                subheadline = "Bewegung in Kitas"
                contentText = "Kinder erleben sich und ihre Umwelt durch Bewegung.<br/>Bewegung hilft Kindern selbstständig zu werden, Selbstvertrauen zu gewinnen sowie Sozialkompetenz und erstes Weltwissen aufzubauen.<br/>Bewegung ist also von zentraler Bedeutung!"

                button={true}
                buttonText="➔ Zum Beitrag von Allgäu.tv"
                buttonDestination={Video}
                buttonTextColor="#2B2E34"
            />

            <Citation
                text="Heute besuchen nahezu alle Kinder ab drei Jahren die Kita. Daher bietet der Lebensraum Kita eine gute Gelegenheit, alle Kinder frühzeitig zu erreichen, dort bewegungsanregende Bedingungen zu schaffen und einen bewegten Lebensstil zu fördern. <br/> <br/>Was braucht die Kita, um ihre Abläufe, Strukturen und die Gesamtorganisation zu einem wirksamen, bewegungsfreundlichen Setting weiterzuentwickeln? "
                image={images.Image_Gym}
                citation={true}
            />
            <TextBoxWithImageInversed
                headline="QueB"
                subtitle="QueB bietet einerseits Werkzeuge wie die Kita-Check-App oder Schrittzählermessungen für Kinder und Mitarbeitende an, andererseits wird die Kita durch einen Queb-Coach begleitet, der die Ziele und Maßnahmen gemeinsam mit dem Team entwickelt. "
                box_headline="Wie geht das?"
                box_text="Wir beschäftigen uns mit der Bedeutung von Bewegung, der aktuellen Situation in der Kita, wieviel Bewegung notwendig ist und wie man Bewegungsaktivitäten fördert. <br/>Am Ende des Coachings wissen Sie, wie Bewegung und Bewegungsförderung in Ihrer Kita gelingen kann, wie Bewegung und Lernen zusammenhängen und haben eigene Ideen zu bewegtem Lernen in ihrer Kita. <br/> <br/>Die Finanzierung des QueB-Coachings übernimmt die Techniker Krankenkasse auf Antrag, die Kita hat keine finanzielle Eigenbeteiligung zu leisten."
                image={images.Image_Playground}
            />

            <Pillars
                headline="QueB will..."
                introText=""

                button={true}
                buttonText = "➔ Weitere Informationen"
                buttonDestination = "https://queb.eu/"
                buttonColor="#ddd0c7"
                buttonBorder="#a1a38e"
                buttonTextColor="#2B2E34"

                leftPillarIcon={images.Icon_ClimbingFrame}
                leftPillarHeadline=""
                leftPillarText="... Kitas bei der Weiterentwicklung zu bewegungsfreundlichen Einrichtungen unterstützen"

                centerPillarIcon={images.Icon_Team}
                centerPillarHeadline=""
                centerPillarText="... Teams unterstützen, ihre Kita als gesunden und bewegten Lebensraum für alle zu gestalten."

                rightPillarIcon={images.Icon_Organization}
                rightPillarHeadline=""
                rightPillarText="... eine Organisationsentwicklung in Kitas anstoßen."
            />
        </div>
    )
}