import imageTitle from './Image_Title.png'
import imageCitation from './Image_Citation.png'
import iconCourses from './Icon_Courses.svg'
import iconChildren from './Icon_Kinder.svg'
import iconBinoculars from './Icon_OutOfTheBox.svg'
import iconQuote from './Icon_Quote.svg'

const ImageTitle = imageTitle;
const ImageCitation = imageCitation;
const IconCourses = iconCourses;
const IconChildren = iconChildren
const IconBinoculars = iconBinoculars
const IconQuote = iconQuote;

// eslint-disable-next-line
export default  {
    ImageTitle,
    ImageCitation,
    IconCourses,
    IconChildren,
    IconBinoculars,
    IconQuote,
}