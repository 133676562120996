import Introduction from "../Components/Introduction";
import images from "./assets/assets";
import {TextBoxWithImage} from "../Components/TextBoxWithImage";
import media from '../../media/assets'
export function Assistenzkraft()
{
    return (
        <div className="courses-page">
            <Introduction
                image = {images.Image_Assistenzkraft_Children}
                headline = "Assistenzkraft"
                subheadline = "Auf dem Weg zur Assistenzkraft"
                contentText = "Kleine Herzen suchen ein großes Herz. Sie haben Freude an der Arbeit mit Kindern und sprechen Deutsch mindestens auf B1 Level?<br/>Sie wollen ihrem beruflichen Leben eine neue Richtung geben?
"
                button={true}
                buttonText="➔ Zur Anmeldung"
                buttonDestination={media.Signup_Form_Assistenzkraft}
                buttonTextColor="#2B2E34"
            />

            <TextBoxWithImage
                headline="Wie geht das?"
                text="Ich begleite Sie auf dem Weg. Sie können sich für die Arbeit in einer Kita/Hort, Großtagespflege und dem schulischen Ganztag qualifizieren und können sich ganz in Ruhe entscheiden, ob Sie sich Ihre berufliche Zukunft dort vorstellen können.<br/>Schon mit Beginn des Modul 2 können Sie als Assistenzkraft in einer Kita beginnen und entsprechend vergütet werden."
                image={images.Image_Assistenzkraft_Painting}
                citation={true}

                button1={true}
                button1Text = "Assistenzkraft Ingolstadt"
                button1Destination = {media.Assistenzkraft_Ingolstadt}
                button1TextColor="#2B2E34"
                button1Subheading="Sept 2024 - Jan 2025"

                button2={true}
                button2Text="Assistenzkraft Kempten 1"
                button2Destination = {media.Assistenzkraft_A_1_Nov}
                button2TextColor="#2B2E34"
                button2Subheading="Nov 2024 - Mar 2025"

                button3={true}
                button3Text="Assistenzkraft Kempten 2"
                button3Destination = {media.Assistenzkraft_A_2_Mar}
                button3TextColor="#2B2E34"
                button3Subheading="Mar 2025 - Juli 2025"

                button4={true}
                button4Text="Assistenzkraft Kempten 2"
                button4Destination ={media.Assistenzkraft_A_2_Sep}
                button4TextColor="#2B2E34"
                button4Subheading="Sept 2024 - Jan 2025"

                button5={true}
                button5Text="➔ Mehr Informationen"
                button5Destination = "https://www.kita-fachkraefte.bayern/"
                button5TextColor="#2B2E34"
            />
        </div>
    )
}