import './css/Introduction.css'
import {Button} from "./Button";

export default function Introduction(props) {
    return (
        <div className="introduction-container">
            <img src={props.image} alt=""/>
            <div className="introduction-text-container">
                <div className="introduction-headline">
                    <p> {props.headline} </p>
                </div>
                <ul>
                    <li className="introduction-subheading">
                        {props.subheadline}
                    </li>
                    <li className="introduction-content">
                        {props.contentText.replaceAll('<br/>','\n')}
                    </li>
                </ul>
                {props.button && (
                    <Button
                        text={props.buttonText}
                        buttonDestination={props.buttonDestination}
                        buttonTextColor={props.buttonTextColor}
                    />
                )}
            </div>
        </div>
    )
}