import styled from "styled-components";
import {openLink} from "../../functions";

export function Button(props){
    const Btn = styled.button`
        background-color: ${props.buttonColor};
        border-color: transparent;
        color: ${props.buttonTextColor};
        padding: 10px 0;
        border-radius: 5px;
        margin: 10px 0;
        cursor: pointer;
        width: 280px;
        font-size: 20px;
        -webkit-box-shadow: 5px 5px 6px 0 rgba(0,0,0,0.16);
        box-shadow: 5px 5px 6px 0 rgba(0,0,0,0.16);

        @media screen and (max-width: 1040px){
            width: 75%;
            font-size: 17px;
            align-self: center;
        }
    `;

    return (
        <Btn
            onClick={()=>{openLink(props.buttonDestination)}}
        >
            <p className="btn_headline">
                {props.text}
            </p>
            <p style={{color: "grey", fontSize: "12pt"}}>
                {props.subheading != null ? props.subheading : ""}
            </p>
        </Btn>
    )
}

