import "./css/Header.css"
import React from 'react';
import images from './assets/assets'
import {GiHamburgerMenu} from "react-icons/gi";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {NavLink, Link, useLocation} from "react-router-dom";


export function Header()
{
    const activeStyle = {
        active: {
            display: "block",
            padding: "25px",
            margin: "-20px 0",
            background: `transparent url(${images.Hover_Background}) no-repeat 0 center`,
            backgroundSize: "100%",
        }
    }

    const [toggleMenu, setToggleMenu] = React.useState(false);

    const path = useLocation();

    function NavigationOverlayToggled(){
        setToggleMenu(!toggleMenu);
    }

    return (
    <div className="header-wrapper">
        <div className="header">

            <div className="header__logo">
                <Link to="/" >
                    <img src={images.logo_svg} alt="Website-Logo"/>
                </Link>
            </div>

            <ul className="header__navbar-links">
                <li>
                    <NavLink
                        to="/"
                        end
                        style={({ isActive }) =>
                            isActive ? activeStyle.active : undefined
                        }
                    >
                        Über Mich
                    </NavLink>
                </li>
                <li className="dropdown">
                    <NavLink
                        to="/courses/queb"
                        id="courses"
                        style={
                            ['/courses/queb', '/courses/ergaenzungskraft'].includes(path.pathname) ? activeStyle.active : undefined
                        }
                    >
                        Kursangebote
                    </NavLink>
                    <div className="dropdown-content slide-in-top">
                        <Link to="/courses/queb" id="dropdown-content-item">QueB</Link>
                        <Link to="/courses/ergaenzungskraft" id="dropdown-content-item">Ergänzungskraft</Link>
                        <Link to="/courses/assistenzkraft" id="dropdown-content-item">Assistenzkraft</Link>
                    </div>
                </li>
                <li>
                    <NavLink
                        to="/workshops"
                        id="workshops"
                        style={({ isActive }) =>
                            isActive ? activeStyle.active : undefined
                        }
                    >
                        Workshops
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/erasmusplus"
                        id="erasmusplus"
                        style={({ isActive }) =>
                            isActive ? activeStyle.active : undefined
                        }
                    >
                        Erasmus +
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/contact"
                        id="contact"
                        style={({ isActive }) =>
                            isActive ? activeStyle.active : undefined
                        }
                    >
                        Kontakt
                    </NavLink>
                </li>
            </ul>
            <div className="header__navbar-links__login">
            <div className="vertical-divider-line"/>
                <a href="https://www.kurse.kita.bayern/login/index.php" target="_blank" rel="noreferrer">Login</a>
            </div>
        </div>

        <div className="header__navbar-smallscreen">
            <GiHamburgerMenu color="#2B2E34" className="hamburger-menu" fontSize="27" onClick={NavigationOverlayToggled}/>

            {toggleMenu && (
                <div className="header__navbar-smallscreen_overlay slide-in-right">
                    <ul className="header__navbar-smallscreen_links">
                        <li>
                            <Link to="/" id="#" onClick={NavigationOverlayToggled}>Über Mich</Link>
                        </li>
                        <li className="courses">
                            <Link to="/courses/queb" id="courses" onClick={NavigationOverlayToggled}>Kursangebote</Link>
                            <Link to="/courses/queb" id="dropdown-content-item-smallscreen" onClick={NavigationOverlayToggled}> QueB</Link>
                            <Link to="/courses/ergaenzungskraft" id="dropdown-content-item-smallscreen" onClick={NavigationOverlayToggled}>Ergänzungskraft</Link>
                            <Link to="/courses/assistenzkraft" id="dropdown-content-item-smallscreen" onClick={NavigationOverlayToggled}>Assistenzkraft</Link>
                        </li>
                        <li className="">
                            <Link to="/workshops" id="workshops" onClick={NavigationOverlayToggled}>Workshops</Link>
                        </li>
                        <li className="">
                            <Link to="/erasmusplus" id="erasmusplus" onClick={NavigationOverlayToggled}>Erasmus +</Link>
                        </li>
                        <li className="">
                            <Link to="/contact" id="contact" onClick={NavigationOverlayToggled}>Kontakt</Link>
                        </li>
                    </ul>
                    <AiOutlineCloseCircle color='#fff' fontSize="27" className="overlay__close" onClick={NavigationOverlayToggled}/>
                </div>
            )}
        </div>
    </div>
    )
}