import assets from './assets/assets'
import Introduction from "../Components/Introduction";
import {TextBoxWithImage} from "../Components/TextBoxWithImage";
import {TextBoxWithImageInversed} from "../Components/TextBoxWithImageInversed";

export function ErasmusPlus()
{
    return (
        <div>
            <Introduction
                headline="Erasmus +"
                image={assets.Image_Paper}
                contentText="Bei Erasmus+ denken viele in erster Linie an Schüler*innen und Student*innen, denen durch dieses Programm Lernaufenthalte in europäischen Ländern ermöglicht werden. Doch Erasmus+ ist auch für Erzieher*innen eine Möglichkeit ins europäische Ausland zu reisen, um dort Erfahrungen zu sammeln und Neues zu lernen."

                button={true}
                buttonText="➔ Zum Beitrag von Allgäu.tv"
                buttonDestination="https://www.allgäu.tv/mediathek/video/europa-im-kindergartenalltag-ministerin-melanie-huml-verleiht-europaurkunde/"
                buttonTextColor="#2B2E34"
            />
            <TextBoxWithImageInversed
                box_headline="Lernaufenthalte"
                image={assets.Image_Stone}
                box_text="Die Lernaufenthalte zeigen uns den Alltag unserer Kolleg*innen in anderen europäischen Ländern, wir lernen Neues über das Bildungsverständnis anderer Kulturen und über ihre Methoden."

            />
            <TextBoxWithImage
                headline="Mein Angebot"
                image={assets.Image_Women}
                text="Ich koordiniere regelmäßig Reisen für Erzieher*innen zu unterschiedlichen Themenfeldern."
                logo={assets.ErasmusPlusLogo}
            />
        </div>
    )
}