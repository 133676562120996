import './css/Legal.css'
import {Impressum as ImpressumComponent } from './Components/Impressum/Impressum'
import {useEffect} from "react";

export function Impressum(){
    useEffect(()=>{
        window.scrollTo(0,130)
    },[])
    return (
        <div className="legal-container">
            <ImpressumComponent/>
            <h1>Icons</h1>
            <p>Telefon: <a href="https://www.flaticon.com/de/kostenlose-icons/anruf" title="anruf Icons">Anruf Icons erstellt von Gregor Cresnar - Flaticon</a></p>
            <p>Mail: <a href="https://www.flaticon.com/de/kostenlose-icons/mail" title="mail Icons">Mail Icons erstellt von Freepik - Flaticon</a></p>
            <p>Zoom: <a href="https://www.flaticon.com/de/kostenlose-icons/zoomen" title="zoomen Icons">Zoomen Icons erstellt von Laisa Islam Ani - Flaticon</a></p>

        </div>
    )
}