import "./css/Pillars.css"
import {Button} from "./Button";

export default function Pillars(props){
    return (
        <div className="outofthebox-container" style={{
            backgroundColor: props.backgroundColor
        }}>
            <p className="outofthebox-container__heading"> {props.headline} </p>
            <p className="outofthebox-container__text">
                {props.introText.replaceAll("<br/>",'\n')}
            </p>

            {props.button && (
                <Button
                    text={props.buttonText}
                    buttonDestination={props.buttonDestination}
                    buttonColor={props.buttonColor}
                    buttonTextColor={props.buttonTextColor}
                />
            )}
            <div className="outofthebox-container__pillar-container">
                <div className="outofthebox-container__pillar" id="left">
                    <img src={props.leftPillarIcon} alt="Intro icon of the left pillar"/>
                    <p id="heading">{props.leftPillarHeadline} </p>
                    <p id="content">{props.leftPillarText.replaceAll("<br/>",'\n')} </p>
                </div>
                <div className="outofthebox-container__pillar" id="center">
                    <img src={props.centerPillarIcon} alt="Intro icon of the center pillar"/>
                    <p id="heading">{props.centerPillarHeadline} </p>
                    <p id="content">{props.centerPillarText.replaceAll("<br/>",'\n')} </p>
                </div>
                <div className="outofthebox-container__pillar" id="right">
                    <img src={props.rightPillarIcon} alt="Intro icon of the right pillar"/>
                    <p id="heading">{props.rightPillarHeadline} </p>
                    <p id="content">{props.rightPillarText.replaceAll("<br/>",'\n')} </p>
                </div>
            </div>
        </div>
    )
}