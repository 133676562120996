import "./css/Footer.css"
import {Link} from "react-router-dom";
export function Footer()
{
    return (
        <div className="footer">
            <div className="footer__list-container">
                <ul className="footer-links">
                    <li>
                        <Link to="/contact" >Kontakt</Link>
                    </li>
                    <li>
                        <Link to="/impressum" >Impressum</Link>
                    </li>
                    <li>
                        <Link to="/datenschutz" >Datenschutz</Link>
                    </li>
                </ul>
            </div>
    </div>
    )
}