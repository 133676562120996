import Image_BewegtesLernen from './Image_BewegtesLernen.png'
import Image_Gym from './Image_Gym.JPG'
import Image_Playground from './Image_Playground.png'
import Icon_ClimbingFrame from './Icon_ClimbingFrame.png'
import Icon_Organization from './Icon_Organization.png'
import Icon_Team from './Icon_Team.png'
import Image_ChickPlayingInMud from './Image_ChildPlayingInMud.png'
import Image_Peacock from './Image_Peacock.png'
import Image_Assistenzkraft_Children from './Image_Assistenzkraft_Children.jpg'
import Image_Assistenzkraft_Painting from './Image_Assistenzkraft_Painting.jpg'
import Video from './video/bildung-und-bewegung-was-hinter-dem-konzept-bewegte-kita-steckt.mp4'

export default {
    Image_Assistenzkraft_Children,
    Image_Assistenzkraft_Painting,
    Image_BewegtesLernen,
    Image_Gym,
    Icon_ClimbingFrame,
    Icon_Team,
    Icon_Organization,
    Image_Playground,
    Image_ChickPlayingInMud,
    Image_Peacock,
    Video
}