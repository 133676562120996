import ErasmusPlusLogo from './erasmusplus-logo.png'
import Image_Paper from './Image_Paper.JPG'
import Image_Stone from './Image_Stone.JPG'
import Image_Women from './Image_Women.JPG'

export default {
    ErasmusPlusLogo,
    Image_Paper,
    Image_Stone,
    Image_Women
}