import './App.css';
import {About} from "./Pages/About/About";
import {Workshops} from "./Pages/Workshops/Workshops";
import {Contact} from "./Pages/Contact/Contact";
import {QuebCoaching} from "./Pages/Courses/QuebCoaching";
import {ErasmusPlus} from "./Pages/Impressions/ErasmusPlus";
import {Header} from "./Pages/Components/Header";
import {Footer} from "./Pages/Components/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Ergaenzungskraft} from "./Pages/Courses/Ergaenzungskraft";
import {PrivacyPolicy} from "./Pages/Legal/PrivacyPolicy";
import {Impressum} from "./Pages/Legal/Impressum";
import {Assistenzkraft} from "./Pages/Courses/Assistenzkraft";

function App() {
  return (
    <>
        <BrowserRouter>
          <Header/>
          <Routes>
              <Route path="/" element={<About/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/courses/queb" element={<QuebCoaching/>}/>
              <Route path="/courses/ergaenzungskraft" element={<Ergaenzungskraft/>}/>
              <Route path="/courses/assistenzkraft" element={<Assistenzkraft/>}/>
              <Route path="/erasmusplus" element={<ErasmusPlus/>}/>
              <Route path="/workshops" element={<Workshops/>}/>
              <Route path="/datenschutz" element={<PrivacyPolicy/>}/>
              <Route path="/impressum" element={<Impressum/>}/>
          </Routes>
        <Footer/>
        </BrowserRouter>
    </>
  );
}

export default App;
